import {
  KFilterSpecificOperators,
  KValueTypes,
  Operators,
} from "../helpers/constants/valueTypes";

export const searchPageMixin = {
  data() {
    return {
      tablePage: 1,
      tablePerPage: 10,
      tableSortingPriority: [],
      totalTablePages: 0,
      totalDocumentNum: 0,
      showFilterModal: false,
      // visibleFilters: [],
      visibleFiltersNames: [],
      visibleFiltersModalColumn: [],
    };
  },
  computed: {
    // className: function () {
    //   if (this.$route.query.class_name) {
    //     return this.$route.query.class_name;
    //   } else {
    //     return null;
    //   }
    // },
    // companyName: function () {
    //   if (this.$route.params.company) {
    //     return this.$route.params.company;
    //   } else {
    //     return null;
    //   }
    // },
    visibleFilters() {
      return this.filters.filter((f) => f.visible);
    },
  },
  watch: {
    visibleFiltersNames(newValue) {
      this.filters.forEach((obj) => {
        if (obj.value !== null && obj.value !== undefined) {
          if (newValue.indexOf(obj.name) === -1) {
            console.log(obj.name);
            obj.value = null;
          }
        }
      });
      // this.visibleFilters = this.filters.filter((f) => f.visible);
    },
  },
  methods: {
    changeFilterOperator(newOperator, filter) {
      if (filter.operator !== newOperator) {
        let newVal = filter.value;
        if (newOperator === Operators.IN.key) {
          newVal =
            filter.value !== null && filter.value !== undefined
              ? [filter.value]
              : [];
        } else if (Array.isArray(filter.value)) {
          newVal = filter.value[0];
        }
        filter.value = null; // Set filter value to null to prevent type check errors before changing operator and so the input component type.
        filter.operator = newOperator;
        filter.value = newVal;
      }
    },
    getFilterOperator(filter) {
      let arr = [];
      const specificOpList = KFilterSpecificOperators[filter.name];
      if (specificOpList && specificOpList.operators) {
        arr = specificOpList.operators;
      } else {
        arr = KValueTypes[filter.type].operators;
      }
      return arr;
    },
    loadFiltersState() {
      const stateString = localStorage.getItem(this.stateKey);
      if (stateString) {
        const restoredState = JSON.parse(stateString);
        this.visibleFiltersNames = restoredState.visible;
        if (this.filters && Array.isArray(this.filters)) {
          this.filters.forEach(
            (f) => (f.visible = this.visibleFiltersNames.indexOf(f.name) !== -1)
          );
        }
      } else if (this.filters && Array.isArray(this.filters)) {
        this.visibleFiltersNames = this.filters.map((f) => f.name);
      }
    },
    onTableColumnsStateClose(columnsState) {
      this.visibleFiltersNames = columnsState.visible;
      this.storeState();
    },
    onFiltersToggleVisibility(visibleObjects) {
      this.visibleFiltersNames = visibleObjects.map((obj) => obj.name);
      this.storeState();
    },
    onTablePage: function (event) {
      this.tablePage = event.page;
      this.tablePerPage = event.rows;
      this.fetchDocuments();
    },
    onTableSort(sortingPriority) {
      this.tableSortingPriority = sortingPriority;
      this.fetchDocuments();
    },
    openDetail(payload) {
      console.log(payload);
    },
    resetForm: function () {
      console.log("resetting");
      for (const prop of this.filters) {
        prop.value = null;
      }
    },
    storeState() {
      let state = {};
      state.visible = this.visibleFiltersNames;
      if (Object.keys(state).length) {
        localStorage.setItem(this.stateKey, JSON.stringify(state));
      }
    },
  },
  created() {
    this.visibleFiltersModalColumn = [
      {
        field: "label",
        label: this.$t("table.col-options.column-label"),
      },
    ];
  },
};
