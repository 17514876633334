var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('b-loading',{attrs:{"active":_vm.isLoading,"is-full-page":false}}),_c('div',{staticClass:"container block is-flex is-justify-content-space-between"},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"refresh"},on:{"click":_vm.refreshSearch}},[_vm._v(" "+_vm._s(_vm.$t("buttons.refresh"))+" ")])],1),_c('div',{staticClass:"container block"},[_c('b-collapse',{staticClass:"card",attrs:{"animation":"slide"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header has-background-primary",attrs:{"role":"button"}},[_c('p',{staticClass:"card-header-title has-text-light"},[_vm._v(" "+_vm._s(_vm.$t("policies.folder.working_list"))+" ")]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up',"type":"is-white"}})],1)])]}}])},[_c('div',{staticClass:"card-content has-background-eurotext-light table-container"},[_c('folder-table',{attrs:{"templateGroups":_vm.workingTemplateGroups,"loading":_vm.loadingWorkingGroups,"paginated":_vm.workingTable.total > _vm.workingTemplateGroups.length,"page":_vm.workingTable.page,"perPage":_vm.workingTable.perPage,"totalResults":_vm.workingTable.total},on:{"templateClick":_vm.onTemplateRowClick,"filter":_vm.getWorkingTemplateGroups,"page-change":function (evt) {
              _vm.workingTable.page = evt.page;
              _vm.getWorkingTemplateGroups(evt.filters);
            }},scopedSlots:_vm._u([{key:"group-buttons",fn:function(){return [_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"buttons is-justify-content-flex-end is-flex-wrap-nowrap"},[_c('b-button',{attrs:{"type":"is-primary","icon-right":"lead-pencil"},on:{"click":function($event){$event.stopPropagation();return _vm.modifyFolder(row)}}}),_c('b-button',{attrs:{"type":"is-danger","icon-right":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFolder(row)}}})],1)]}}])})]},proxy:true}])})],1)])],1),_c('div',{staticClass:"container block"},[_c('b-collapse',{staticClass:"card",attrs:{"animation":"slide"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header has-background-primary",attrs:{"role":"button"}},[_c('p',{staticClass:"card-header-title has-text-light"},[_vm._v(" "+_vm._s(_vm.$t("policies.folder.list"))+" ")]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up',"type":"is-white"}})],1)])]}}])},[_c('div',{staticClass:"card-content has-background-eurotext-light"},[_c('folder-table',{attrs:{"templateGroups":_vm.templateGroups,"loading":_vm.loadingGroups,"paginated":true,"page":_vm.table.page,"perPage":_vm.table.perPage,"totalResults":_vm.table.total},on:{"templateClick":_vm.onTemplateRowClick,"filter":_vm.getTemplateGroups,"page-change":function (evt) {
              _vm.table.page = evt.page;
              _vm.getTemplateGroups(evt.filters);
            }},scopedSlots:_vm._u([{key:"group-buttons",fn:function(){return [_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"buttons is-justify-content-flex-end is-flex-wrap-nowrap"},[_c('b-button',{attrs:{"type":"is-warning","icon-left":"account-cash"},on:{"click":function($event){$event.stopPropagation();return _vm.viewPayMerchant(row)}}}),_c('b-button',{attrs:{"type":"is-primary","icon-left":"content-copy"},on:{"click":function($event){$event.stopPropagation();return _vm.openGroupCreation(row)}}},[_vm._v(_vm._s(_vm.$t("policies.folder.button.clone")))]),_c('b-button',{attrs:{"type":"is-danger","icon-right":"delete"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFolder(row)}}})],1)]}}])})]},proxy:true},{key:"bottom-left",fn:function(){return [_c('div',{staticClass:"container"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openGroupCreation(null)}}},[_vm._v(_vm._s(_vm.$t("policies.folder.button.new_folder")))])],1)]},proxy:true}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }