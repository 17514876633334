<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title mr-4">
        {{ $t("policies.pay_merchant.top_dropdown_label") }}
      </p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section
      class="modal-card-body has-text-centered"
      style="min-height: 200px"
    >
      <pay-merchant-dropdown
        :payMerchants="payMerchants"
        :selected.sync="selected"
        @update-selected="updateSelected"
      ></pay-merchant-dropdown>
    </section>
    <footer class="modal-card-foot">
      <b-button :label="$t('buttons.save')" type="is-primary" @click="onsave" />
      <b-button :label="$t('buttons.cancel')" @click="$emit('close')" />
    </footer>
  </div>
</template>

<script>
import PayMerchantDropdown from "./PayMerchantDropdown.vue";
export default {
  components: { PayMerchantDropdown },
  props: {
    payMerchants: {
      type: Array,
      default: () => [],
    },
    defaultUname: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    onsave() {
      console.log("Selected", this.selected);
      this.$emit("save", this.selected);
      this.$emit("close");
    },
    updateSelected(selected) {
      console.log("Selected updated", selected);
      this.selected = selected;
    },
  },
  created() {
    if (this.defaultUname) {
      const filtered = this.payMerchants.filter(
        (p) => p.nickname === this.defaultUname
      );
      if (filtered.length > 0) this.selected = filtered.pop();
      else this.selected = null;
    }
  },
};
</script>

<style></style>
