<template>
  <section class="section">
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>

    <!-- Refresh button -->
    <div class="container block is-flex is-justify-content-space-between">
      <b-button type="is-primary" @click="refreshSearch" icon-left="refresh">
        {{ $t("buttons.refresh") }}
      </b-button>
    </div>

    <!-- Working models -->
    <div class="container block">
      <b-collapse class="card" animation="slide">
        <template #trigger="props">
          <div class="card-header has-background-primary" role="button">
            <p class="card-header-title has-text-light">
              {{ $t("policies.folder.working_list") }}
            </p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'"
                type="is-white"
              >
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content has-background-eurotext-light table-container">
          <folder-table
            :templateGroups="workingTemplateGroups"
            :loading="loadingWorkingGroups"
            :paginated="workingTable.total > workingTemplateGroups.length"
            :page="workingTable.page"
            :perPage="workingTable.perPage"
            :totalResults="workingTable.total"
            @templateClick="onTemplateRowClick"
            @filter="getWorkingTemplateGroups"
            @page-change="
              (evt) => {
                workingTable.page = evt.page;
                getWorkingTemplateGroups(evt.filters);
              }
            "
          >
            <template #group-buttons>
              <b-table-column v-slot="{ row }">
                <div
                  class="buttons is-justify-content-flex-end is-flex-wrap-nowrap"
                >
                  <b-button
                    type="is-primary"
                    icon-right="lead-pencil"
                    @click.stop="modifyFolder(row)"
                  ></b-button>
                  <b-button
                    type="is-danger"
                    icon-right="delete"
                    @click.stop="deleteFolder(row)"
                  ></b-button>
                </div>
              </b-table-column>
            </template>
          </folder-table>
        </div>
      </b-collapse>
    </div>

    <!-- Available groups -->
    <div class="container block">
      <b-collapse class="card" animation="slide">
        <template #trigger="props">
          <div class="card-header has-background-primary" role="button">
            <p class="card-header-title has-text-light">
              {{ $t("policies.folder.list") }}
            </p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'"
                type="is-white"
              >
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content has-background-eurotext-light">
          <folder-table
            :templateGroups="templateGroups"
            :loading="loadingGroups"
            :paginated="true"
            :page="table.page"
            :perPage="table.perPage"
            :totalResults="table.total"
            @templateClick="onTemplateRowClick"
            @filter="getTemplateGroups"
            @page-change="
              (evt) => {
                table.page = evt.page;
                getTemplateGroups(evt.filters);
              }
            "
          >
            <template #group-buttons>
              <b-table-column v-slot="{ row }">
                <div
                  class="buttons is-justify-content-flex-end is-flex-wrap-nowrap"
                >
                  <b-button
                    type="is-warning"
                    icon-left="account-cash"
                    @click.stop="viewPayMerchant(row)"
                  ></b-button>
                  <b-button
                    type="is-primary"
                    icon-left="content-copy"
                    @click.stop="openGroupCreation(row)"
                    >{{ $t("policies.folder.button.clone") }}</b-button
                  >
                  <b-button
                    type="is-danger"
                    icon-right="delete"
                    @click.stop="deleteFolder(row)"
                  ></b-button>
                </div>
              </b-table-column>
            </template>

            <!-- <template #footer>
              <div class="container is-flex is-justify-content-flex-end">
                <b-button
                  type="is-primary"
                  style="margin-right: 1.8rem"
                  @click="openGroupCreation(null)"
                  >{{ $t("policies.folder.button.new_folder") }}</b-button
                >
              </div>
            </template> -->
            <template #bottom-left>
              <div class="container">
                <b-button type="is-primary" @click="openGroupCreation(null)">{{
                  $t("policies.folder.button.new_folder")
                }}</b-button>
              </div>
            </template>
          </folder-table>
        </div>
      </b-collapse>
    </div>
  </section>
</template>

<script>
import { commonMixin, templateMixin } from "@/mixins";
// import { policiesService } from "@/services";
import FolderTable from "../../components/policies/FolderTable.vue";
//import PayMerchantDropdown from "../../components/policies/PayMerchantDropdown.vue";
import { policiesService } from "../../services";
import PayMerchantModalVue from "../../components/policies/PayMerchantModal.vue";
export default {
  components: { FolderTable },
  mixins: [commonMixin, templateMixin],
  data() {
    return {
      isOpen: 0,
      templateGroups: [],
      workingTemplateGroups: [],
      loadingGroups: false,
      loadingWorkingGroups: false,
      openingPreview: false,
      merchants: [],
      selectedMerchant: null,
      table: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      workingTable: {
        page: 1,
        perPage: 5,
        total: 0,
      },
      page: 1,
      perPage: 50,
    };
  },
  computed: {
    isLoading() {
      return (
        this.loadingGroups || this.loadingWorkingGroups || this.openingPreview
      );
    },
  },
  methods: {
    columnTdAttrs() {
      return {
        style: {
          "text-align": "left !important",
        },
      };
    },
    asdf() {
      console.log("delete");
    },
    refreshSearch() {
      this.getTemplateGroups();
      this.getWorkingTemplateGroups();
    },
    getTemplateGroups(filters) {
      this.loadingGroups = true;
      this._getTemplateGroups(
        false,
        this.table.page,
        this.table.perPage,
        filters
      )
        .then((res) => {
          this.templateGroups = res["groups"];
          this.table.total = res["items_found"];
        })
        .finally(() => (this.loadingGroups = false));
    },
    getWorkingTemplateGroups(filters) {
      this.loadingWorkingGroups = true;
      this._getTemplateGroups(
        true,
        this.workingTable.page,
        this.workingTable.perPage,
        filters
      )
        .then((res) => {
          this.workingTemplateGroups = res["groups"];
          this.workingTable.total = res["items_found"];
        })
        .finally(() => (this.loadingWorkingGroups = false));
    },
    _getTemplateGroups(temporary, page, perPage, filters) {
      return policiesService.fetchTemplateGroups(
        this.companyName,
        this.merchantUniqueName,
        null,
        temporary,
        page,
        perPage,
        null,
        filters
      );
    },
    viewPayMerchant(folder) {
      const defPayMerchant = folder["payment_merchant"];
      this.$buefy.modal.open({
        parent: this,
        component: PayMerchantModalVue,
        hasModalCard: true,
        customClass: "",
        trapFocus: true,
        props: {
          payMerchants: this.payMerchants,
          defaultUname: defPayMerchant,
        },
        events: {
          save: (obj) => {
            console.log("ObjToUpdate", obj);
            this.updateFolder(folder, obj);
          },
        },
      });
    },
    updateFolder(folder, payMerchant) {
      console.log("saving", payMerchant);
      policiesService
        .updateTGroupPayMerchant(
          this.companyName,
          folder.name,
          payMerchant.nickname
        )
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("upload.messages.upload_success"),
            type: "is-success",
            duration: 4000,
            position: "is-top-right",
            // container: "#mainCol",
          });
          // reload pay merchants on save
          this.fetchPayMerchants();
          this.refreshSearch();
        });
    },
    confirmDelete(folder) {
      this.$buefy.dialog.confirm({
        message: this.$t("policies.folder.", { name: folder.name }),
        cancelText: this.$t("buttons.cancel"),
        // confirmText: this.$t("buttons.continue"),
        type: "is-danger",
        onConfirm: () => this.deleteFolder(folder),
      });
    },
    deleteFolder(folder) {
      policiesService
        .deleteTemplateGroup(
          this.companyName,
          folder.name,
          this.merchantUniqueName
        )
        .then(() => {
          this.refreshSearch();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (error.response.data.code === -10111) {
              this.$buefy.toast.open({
                message: this.$t("policies.folder.error.not_exists", {
                  name: folder.name,
                }),
                type: "is-danger",
                duration: 4000,
              });
            } else if (error.response.data.code === -10112) {
              this.$buefy.toast.open({
                message: this.$t("policies.folder.error.still_in_use", {
                  name: folder.name,
                }),
                type: "is-danger",
                duration: 4000,
              });
            }
          }
        });
    },
    modifyFolder(folder) {
      const tab = {
        id: `mod_folder_${folder.id}`,
        component: "GroupTemplateCreation",
        label: `Mod folder`,
        // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
        props: {
          companyName: this.companyName,
          folderData: folder,
          modify: true,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    newFolderFrom(folder) {
      const tab = {
        id: `mod_folder_${folder.id}`,
        component: "GroupTemplateCreation",
        label: `New folder`,
        // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
        props: {
          companyName: this.companyName,
          folderData: folder,
          modify: false,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
    onGroupClick() {},
    async onTemplateRowClick(row) {
      try {
        this.openingPreview = true;

        const templateId = row.id;
        const templateName = row.name;

        const metadata = await policiesService.fetchTemplateMetadata(
          this.companyName,
          templateId
        );

        const samplePdf = await policiesService.fetchTemplateSamplePdf(
          this.companyName,
          templateId
        );
        // const pdfData = atob(samplePdf);

        // const fData = Uint8Array.from(pdfData);
        // const file = new File([fData], "file name");
        const fData = Uint8Array.from(
          atob(samplePdf)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        const file = new File([fData], `${templateName}.pdf`, {
          type: "application/pdf",
        });

        const template = { file, metadata };

        const tab = {
          id: `pdf_${templateId}`,
          component: "Pdf",
          label: `Templ. ${templateName}`,
          // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
          props: {
            template: template,
            showTemplateInfo: false,
            viewOnly: true,
          },
        };
        this.$store.dispatch("tabs/openTab", tab);
      } catch (error) {
        console.error(error);
      } finally {
        this.openingPreview = false;
      }
    },
    openGroupCreation(folder = null) {
      const tab = {
        id: `new_folder_${folder ? folder.id : this.companyName}`,
        component: "GroupTemplateCreation",
        label: folder
          ? this.$t("policies.tabs.from")
          : this.$t("policies.tabs.new"),
        // icon: this.$config.icons.tabs.document_detail, // TODO: add icon
        props: {
          companyName: this.companyName,
          folderData: folder,
          modify: false,
        },
      };
      this.$store.dispatch("tabs/openTab", tab);
    },
  },
  mounted() {
    // this.fetchMerchants().then(() => {
    //   this.refreshSearch();
    // });
    this.refreshSearch();
    this.fetchPayMerchants();
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
